<template>
  <Teleport to="body">
    <Transition name="fade">
      <div v-if="shown" class="modal-wrap" @mousedown.self="emit('close')" v-bind="$attrs">
        <div class="modal" :class="{ 'modal--wide': wide, 'modal--align-top': alignTop }" @mousedown.stop>
          <MobileOnly v-if="title"><MobilePopupHeader class="modal__header" :title="title" @close="emit('close')" /></MobileOnly>
          <div class="modal__body">
            <slot />
            <button class="modal-close-btn" v-if="closeButton" title="Закрыть" @click="emit('close')">
              <IconX class="icon" />
            </button>
          </div>
          <div v-if="$slots.navbar" class="modal__navbar"><slot name="navbar" /></div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { IconX } from '@tabler/icons-vue'

defineOptions({ inheritAttrs: false })

const props = defineProps({
  shown: Boolean,
  closeButton: Boolean,
  wide: Boolean,
  title: String,
  alignTop: Boolean
})
const emit = defineEmits(['close'])

useModal(props)
</script>

<style scoped lang="scss">
.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 72px 16px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;

  @media (min-width: 920px) {
    display: grid;
    align-items: center;
  }
  @media (max-width: 920px) {
    padding: 0;
  }
}

.modal-scroll-container {
  height: 100%;
}

.modal {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background: var(--color-bg) !important;
  color: var(--color-elements-primary);
  border-radius: 16px;
  max-width: 452px;
  width: 100%;
  //max-width: calc(100% - 16px * 2);
  opacity: 1;
  transform: scale(1);
  display: flex;
  flex-direction: column;

  &--wide {
    max-width: 1032px;
  }

  @media (max-width: 920px) {
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    min-height: 100%;
  }

  &__body {
    flex: 1;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    @media (max-width: 920px) {
      padding: 16px 15px;
    }
  }
  &--align-top &__body {
    justify-content: flex-start;
  }
  :deep(.modal__title) {
    text-align: center;
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    @media (max-width: 920px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  :deep(.modal__desc) {
    text-align: center;
    margin-top: 8px;
    @media (max-width: 920px) {
      margin-top: 16px;
    }
  }
  &__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px solid var(--color-separator);
    position: sticky;
    bottom: -72px;
    background: var(--color-bg);
    z-index: 9999;
    border-radius: 0 0 16px 16px;
    @media (max-width: 920px) {
      bottom: 0;
      border-radius: 0;
      padding: 10px 15px;
    }
  }
}

.modal-close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  color: var(--color-elements-tertiary);
  transition: .2s color;
  &:hover {
    color: var(--color-elements-secondary);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: .2s opacity, .2s transform;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>
